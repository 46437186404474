.page-login {
	display: flex;
	padding: 0 20px;
	align-items: center;
    justify-content: center;
	height: 100vh;
	overflow-y: auto;
	/* background: linear-gradient(240deg, var(--whiteColor), var(--offWhiteColor), var(--grayColor)); */
	background: linear-gradient(240deg, var(--primaryColor), var(--whiteColor));
	background-size: 200%;
	animation: waves 5s ease infinite;
}
/* Animate Background */
@keyframes waves {
    0%{background-position:0% 25%}
    50%{background-position:100% 50%}
    100%{background-position:0% 25%}
}
.login-part{
    padding: 50px 35px;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	max-width: 500px;
	width: 100%;
	background-color: var(--whiteColor);
	box-shadow: 0px 1px 25px -10px var(--whiteColor);
	border-radius: 8px;
}
.login-part .login-header {
    width: 100%;
    margin: 0 0 35px;
	text-align: center;
}

.page-login img.site-logo {
	max-width: 315px;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    height: 90px;
}

/* LOGIN FORM  */
.loginForm {
	width: 100%;
}
.loginForm .input-field {
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.loginForm .input-field label {
	display: block;
	font-weight: 400;
	width: 100%;
	font-size: 16px;
	margin: 0 0 8px;
	color: var(--tertiaryColor);
	text-transform: capitalize;
}
.loginForm p.validation-error {
    color: var(--errorColor);
    font-size: 14px;
	font-weight: 500;
	margin: 0;
}

.loginForm .submitBtn {
    cursor: pointer;
	position: relative;
    background: var(--primaryColor);
    color: var(--whiteColor);
    font-weight: 600;
    border-radius: 6px;
    font-size: 18px;
    width: 100%;
    margin: 25px 0 6px;
    letter-spacing: 0.5px;
	transition: 0.4s all;
}
.loginForm .submitBtn:hover {
    background: var(--darkBlue);
	transition: 0.4s all;
}
.loginForm .disableBtn {
    cursor: pointer;
	position: relative;
    background: var(--grayColor);
    color: var(--secondaryColor);
    font-weight: 600;
    border-radius: 6px;
    font-size: 18px;
    width: 100%;
    margin: 25px 0 6px;
    letter-spacing: 0.5px;
	transition: 0.4s all;
}
.login-part header.login-header .logo {
	max-width: 216px;
    height: 137px;
	margin-bottom: 0px;
    margin-top: 0px;
    background: transparent;
}