/* Untitled SECTION */
.untitled-title {
    display: flex;
    align-items: center;
    margin: 0 0 16px;
}
.untitled-title svg {
    color: var(--primaryColor);
    font-size: 30px;
    margin: 0px 5px 0px 0px;
}
.unpublish-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 16px 8px;
    background-color: var(--primaryColorAlpha);
    border-radius: 6px;
	margin: 0 0 16px 0;
}
.unpublish-box .content {
    padding: 0 12px 0 0;
    width: 100%;
}
.unpublish-box .content h6 {
	display: flex;
	align-items: center;
	color: var(--primaryColor);
	font-weight: 600;
}
.unpublish-box .content h6 svg {
	margin: 0 6px 0 0;
    font-size: 22px;
}
.unpublish-box .content p {
	color: var(--secondaryColor);
}


/* Create Object ~ Modal */
.object-box-part {
    display: flex;
    flex-wrap: wrap;
}
.send-mail-modal .object-box-part .object-boxes {
    width: 20% !important;
	transition: 0.4s all;
	background-color: var(--whiteColor);
    text-align: center;
	border-radius: 4px;
}
.send-mail-modal.modal-footer-hide .ant-modal-footer {
	display: none;
}
.object-box-part .object-boxes .inner-area {
    padding: 16px 8px 10px;
	cursor: pointer;
}
.object-box-part .object-boxes .inner-area svg {
	transition: 0.4s all;
	margin: 0 0 6px;
    font-size: 32px;
	color: var(--secondaryColor);
}
.object-box-part .object-boxes .inner-area p {
	color: var(--tertiaryColor);
	font-weight: 500;
	transition: 0.4s all;
	margin: 0px;
}
.object-box-part .object-boxes .select-disabled {
	cursor: auto;
}
.object-box-part .object-boxes:hover {
	transition: 0.4s all;
	background-color: var(--primaryColorAlpha);
}
.object-box-part .object-boxes:hover svg {
	transition: 0.4s all;
	color: var(--primaryColor);
}
.object-box-part .object-boxes:hover p {
	transition: 0.4s all;
	color: var(--primaryColor);
}

/*  */
.layout-box-part {
    display: flex;
    flex-wrap: wrap;
}
.send-mail-modal .layout-box-part .layout-box {
    width: 50% !important;
	transition: 0.4s all;
	background-color: var(--whiteColor);
    text-align: center;
	border-radius: 4px;
}
.layout-box-part .layout-box .inner-area {
    padding: 16px 8px 10px;
	cursor: pointer;
}
.layout-box-part .layout-box .inner-area svg {
	margin: 0 0 6px;
	font-size: 72px;
	color: var(--secondaryColor);
	transition: 0.4s all;
}
.layout-box-part .layout-box .inner-area p {
	margin: 0px;
	font-weight: 500;
	color: var(--tertiaryColor);
	transition: 0.4s all;
}
.send-mail-modal .layout-box-part .layout-box:hover {
	background-color: var(--primaryColorAlpha);
	transition: 0.4s all;
}
.send-mail-modal .layout-box-part .layout-box:hover p,
.send-mail-modal .layout-box-part .layout-box:hover svg {
	transition: 0.4s all;
	color: var(--primaryColor);
}


/* SAFTY FORM */
.safetyform-card-body {
	height: calc(100vh - 190px);
    padding: 0 0 0 24px;
}
.safetyform-field-part {
	height: 100%;
	display: flex;
	flex-wrap: wrap;
    justify-content: space-between;
}
.add-field-area {
    width: 100%;
    max-width: 375px;
    padding: 24px 0;
}

.field-area {
    padding: 24px 16px;
	width: 100%;
	max-width: calc(100% - 728px);
    height: 100%;
    overflow-y: auto;
}

.iphone-view-area {
    padding: 50px 0 0;
	width: 100%;
	max-width: 350px;
	height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    background-color: var(--primaryColorAlpha);
}
.iphone-view-area .iphone-body {
    width: 350px;
	user-select: none;
}
.safetyform-field-part .description-btn {
	background-color: transparent;
	border-color: var(--primaryColor);
	color: var(--primaryColor);
	display: table;
	margin: 0 auto;
	max-width: 200px;
	width: 100%;
	height: 44px;
	border-radius: 40px;
	cursor: pointer;
	transition: 0.4s all;
}
.safetyform-field-part .description-btn:hover {
	color: var(--whiteColor);
}
.safetyform-field-part hr.devider {
    margin: 24px 0;
    height: 0px;
    border: 1px solid var(--primaryColorAlpha);
}
.safetyform-field-part .fields-area {
	max-height: calc(100vh - 500px);
}
.safetyform-field-part .safetyform-fields {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.safetyform-field-part .safetyform-fields .template-btn {
    margin: 0 0 16px;
    width: calc(50% - 5px);
	color: var(--tertiaryColor);
    background: var(--primaryColorAlpha);
    font-size: 14px;
    border-radius: 50px;
    padding: 8px 16px;
    text-align: center;
    font-weight: 500;
    cursor: pointer;
	transition: 0.4s all;
	display: flex;
	align-items: center;
	justify-content: center;
}
.safetyform-field-part .safetyform-fields .template-btn svg,
.safetyform-field-part .safetyform-fields .template-btn i {
	margin: 0 2px 0 0;
}
.safetyform-field-part .safetyform-fields .template-btn:hover {
	transition: 0.4s all;
	background-color: var(--primaryColor);
	color: var(--whiteColor);
}
.safetyform-field-part .show-more-btn {
	background-color: transparent;
	border-color: var(--primaryColor);
	color: var(--primaryColor);
	letter-spacing: 0.5px;
	display: table;
	margin: 12px auto 0;
	font-weight: 500;
	max-width: 100%;
	width: 100%;
	height: 44px;
	border-radius: 40px;
	cursor: pointer;
	transition: 0.4s all;
}
.safetyform-field-part .show-more-btn:hover {
	color: var(--whiteColor);
}
.safetyform-field-part .bottom-btn {
	display: flex;
	justify-content: space-between;
}
.safetyform-field-part .bottom-btn button {
    width: calc(50% - 8px);
    margin: 16px 0 0;
}
.safetyform-field-part .bottom-btn button.solid {
	color: var(--whiteColor);
	background-color: var(--primaryColor);
}
.safetyform-field-part .bottom-btn button.solid:hover {
	background-color: var(--darkBlue);
}


/* Field Lists */
.field-area .no-field-lists {
	display: flex;
	flex-wrap: wrap;
    align-items: center;
    justify-content: center;
	text-align: center;
    flex-direction: column;
    height: 100%;
}
.field-area .field-lists {
	position: relative;
	display: flex;
    flex-wrap: wrap;
    align-items: center;
	transition: 0.4s all;
	width: calc(100% - 50px);
    padding: 12px 16px 12px 28px;
    margin: 0 0 16px 0;
    background-color: var(--whiteColor);
    box-shadow: 0px 0px 2px 1px rgba(145,158,171,0.16);
    border-radius: 4px;
    cursor: pointer;
}
.field-area .field-lists:hover {
	box-shadow: rgba(145,158,171,0.16) 0px 16px 32px -4px;
	transition: 0.4s all;
}
.field-area .field-index {
    display: flex;
    align-items: center;
    justify-content: center;
	border: 1px solid var(--grayColor);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    font-size: 12px;
	font-weight: 500;
    margin: 0 16px 12px 0;
	cursor: grabbing;
}
.field-area .field-lists .field-content {
	margin: 0px;
    width: calc(100% - 110px);
}
.field-area .field-lists .field-content .title-label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    color: var(--tertiaryColor);
}
.field-area .field-lists .field-content .subtexts {
	font-size: 12px;
    color: var(--secondaryColor);
    margin: 0;
    display: inline-block;
    border-radius: 14px;
    font-weight: 500;
	text-transform: capitalize;
}
.field-area .field-lists .copy-delete-icons {
    display: flex;
    align-items: center;
    width: 60px;
    opacity: 0;
}
.field-area .field-lists .copy-delete-icons .copy-icon {
	color: black;
}
.field-area .field-lists .copy-delete-icons button {
	padding: 0;
    background-color: transparent;
    border: none;
    height: auto;
    font-size: 18px;
	cursor: pointer;
	transition: 0.4s all;
}
.field-area .field-lists .copy-delete-icons button:hover i {
	transition: 0.4s all;
	transform: scale(0.9);
}
.field-area .field-lists:hover .copy-delete-icons {
	transition: 0.4s all;
	opacity: 1;
}
.field-area .field-lists:hover img.drag-dots {
	transition: 0.4s all;
	opacity: 1;
    left: 14px;
}



/* Safty Form ~ MODAL */
.form-export-part {
    margin: 0 0 18px 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.form-export-part .form-export {
	display: flex;
}
.form-export-part .form-export p {
	padding: 0 0 0 8px;
    margin: 0 0 0 8px;
	cursor: pointer;
	color: var(--secondaryColor);
    border-left: 1px solid var(--secondaryColor);
	transition: 0.3s all;
}
.form-export-part .form-export p:first-child {
	border-left: none;
}
.form-export-part .form-export p:hover {
	transition: 0.3s all;
	color: var(--primaryColor);
}

.drag-items {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
    justify-content: space-between;
}
.drag-items img.drag-dots {
    position: absolute;
	width: 100%;
    max-width: 12px;
    left: 0px;
	opacity: 0;
	transition: 0.3s all;
	cursor: grab;
}
.drag-items .field-icons {
    font-size: 20px;
    margin: 0 10px 0 6px;
}
.drag-items .ant-form-item {
	width: calc(100% - 14px);
	margin-bottom: 0 !important;
    padding: 0 0 0 8px !important;
}
.drag-items .ant-form-item input {
	background-color: var(--whiteColor) !important;
}
.drag-items.dropdown-drag {
	margin: 0 0 12px 0;
}
.drag-items.dropdown-drag img.drag-dots {
	position: relative;
	opacity: 1;
    margin: 30px 0 0;
}
button.site-btn.add-field {
    width: 100%;
    max-width: 118px;
    font-size: 14px;
    font-weight: 500;
    height: 36px;
    padding: 4px;
}
.field-hr-divider {
	margin: 24px 0;
    border: none;
    border-bottom: 1px solid var(--primaryColorAlpha);
}
.field-checkbox {
    display: block;
    margin: 8px 0 0;
}
.field-range-slider {
	display: flex;
	flex-wrap: wrap;
    align-items: center;
}
.field-range-slider .devider-text {
	padding: 0 12px;
}

/* Responsive */
@media (max-width: 1440px) {
	.safetyform-card-body {
		padding: 0 0 0 12px;
	}
	.field-area .field-lists {
		padding: 12px 10px 12px 28px;
		margin: 0 0 12px 0;
	}
	.add-field-area {
		max-width: 330px;
	}
	.field-area {
		padding: 24px 14px;
		max-width: calc(100% - 682px);
	}
	.safetyform-field-part .safetyform-fields .template-btn {
		padding: 8px 14px;
		margin: 0 0 10px;
		font-size: 12px;
		width: calc(50% - 4px);
	}
}
@media (max-width: 1280px) {}
@media (max-width: 1199px) {
	.add-field-area {
		max-width: 200px;
	}
	.field-area {
		max-width: calc(100% - 552px);
	}
	.safetyform-field-part .description-btn {
		font-size: 14px;
		font-weight: 600;
		height: 40px;
		max-width: 164px;
	}
	.safetyform-field-part .safetyform-fields .template-btn {
		width: 100%;
	}
	.safetyform-field-part .show-more-btn {
		max-width: 160px;
		width: 100%;
		height: 38px;
	}
	.safetyform-field-part .bottom-btn button {
		font-size: 12px;
	}
}
@media (max-width: 1024px) {
	.safetyform-card-body {
		height: auto;
		padding: 0px;
	}
	.add-field-area {
		max-width: 290px;
		padding: 24px 0px 25px 16px;
	}
	.safetyform-field-part .safetyform-fields .template-btn {
		width: calc(50% - 4px);
	}
	.field-area {
		padding: 24px 14px 0;
		margin: 0 0 16px 0;
		max-width: calc(100% - 292px);
		max-height: 520px;
	}
	.iphone-view-area {
		max-width: 100%;
		height: auto;
	}
	.iphone-content-part {
		display: table;
		margin: 0 auto 20px;
	}
	.bottom-btn {
		right: 0;
		left: auto;
	}
}

.IoIosAddCircleOutline-custom {
	font-size: 20px;
	font-weight: bolder
}
.signup-form-container {
	display: flex;
	justify-content: space-between;
}

.signup-form-content {
	display: flex
}
.addFieldBtn-span {
	font-weight: 400
}

.bottom-btn button {
	margin: 0 20px;
	border-radius: 40px;
	background-color: transparent;
	border-color: var(--primaryColor);
	color: var(--primaryColor);
}

.bottom-btn button:hover {
	color: var(--whiteColor);
}

.bottom-btn-disabled button {
	margin: 0 20px;
	border-radius: 40px;
	background-color: transparent;
	color: var(--primaryColor);
	border-color: #d0d0d0;
    opacity: 0.5;
	cursor: auto;
}

.object-details {
    position: relative;
	cursor: pointer;
    width: auto;
    height: 40px;
    font-size: 12px;
    font-weight: 500;
    max-width: 200px;
    padding: 4px 16px;
    border: 1px solid var(--primaryColor);
    border-radius: 4px;
    transition: 0.4s all;
	margin-bottom: 10px;
	display: flex;
    align-items: center;
}
.object-details .IoIosAddCircleOutline-custom {
	margin: 0 4px 0 0;
	font-size: 18px;
}
.object-details .edit-delete-icon {
	font-size: 12px;
	margin: 0 0 0 6px;
}
.object-details .drag-dots {
	max-width: 8px;
    position: absolute;
    left: 0px;
    opacity: 0;
	transition: 0.3s all;
	cursor: grab;
}
.object-details:hover .drag-dots {
	opacity: 1;
	left: 5px;
	transition: 0.3s all;
}
.object-details span {
	width: calc(100% - 40px);
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

/* Plugin Editor Css */
.editor-content-area {
	border: 1px solid var(--offWhiteColor);
	margin: 0 0 8px;
	min-height: 140px;
    max-height: 360px;
}
.editor-part .rdw-image-modal-btn:disabled {
	color: var(--secondaryColor);
}
.editor-part .rdw-embedded-modal button {
	font-size: 12px;
	font-weight: 600;
	color: var(--tertiaryColor);
}
.editor-part .rdw-embedded-modal-header,
.editor-part .rdw-editor-toolbar {
	font-size: 12px;
}