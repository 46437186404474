.site-header {
  position: relative;
  z-index: 999;
  background: var(--whiteColor);
  height: 68px;
  padding: 0;
  box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.10);
}
.site-header .inner-area {
  padding: 0 20px;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}
.site-header .inner-area > .ant-row {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.site-header .site-logo {
  max-width: 200px;
  width: 100%;
}
.site-header .left-area {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.site-header .right-area {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* Search Input */
.site-header .header-search-input {
  padding: 0 0 0 30px;
  max-width: 300px;
  transition: 0.6s all;
}
.site-header .header-search-input input {
  padding: 6px 14px;
  font-size: 14px;
  height: 44px;
	background: transparent;
  color: var(--tertiaryColor);
  box-shadow: none;
	border: 1px solid var(--grayColor);
	border-color: var(--grayColor) !important;
}
.site-header .header-search-input input:hover {
	border: 1px solid;
	border-color: var(--tertiaryColor) !important;
}
.site-header .header-search-input input:focus {
	border: 1px solid;
	border-color: var(--tertiaryColor) !important;
}
.site-header .header-search-input .anticon-search svg {
  width: 18px;
  height: 22px;
  fill: var(--secondaryColor);
}

/* Notification Bell */
.site-header .notification-bell {
  color: var(--secondaryColor);
  transition: 0.4s all;
  cursor: pointer;
  margin: 0 16px 0 0;
}
.site-header .notification-bell:hover {
  color: var(--primaryColor);
  transition: 0.4s all;
}

/* User Dropdown */
.site-header .user-profile-dropdown {
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.site-header .user-profile-dropdown .avatar-circle {
  width: 44px;
  height: 44px;
  font-size: 16px;
  line-height: 46px;
  background-color: var(--primaryColor);
  color: var(--whiteColor);
  font-weight: 600;
  letter-spacing: 0.5px;
  text-align: center;
  border-radius: 50%;
  transition: 0.5s all;
}
.site-header .user-profile-dropdown .avatar-circle:hover {
  transform: scale(1.1);
  transition: 0.5s all;
  background-color: var(--darkBlue);
}
.site-header .user-profile-dropdown.ant-dropdown-open .avatar-circle {
  transform: scale(1.1);
  transition: 0.5s all;
  background-color: var(--darkBlue);
}
.site-header .user-profile-dropdown .user-name {
  height: 20px;
  line-height: 1.6;
}
.site-header .user-profile-dropdown .user-name span {
  line-height: 1.6;
  font-size: 14px;
  padding: 0 0 0 8px;
  color: var(--secondaryColor);
}
/* User Dropdown ~ Box */
.dropdown-boxuser {
  min-width: 190px;
  max-width: 190px;
  padding: 12px 8px;
  margin: 8px 0 0;
  background-color: var(--whiteColor);
  border-radius: 4px;
  box-shadow: rgba(0,0,0,0.1) 0px 0px 2px 0px, rgba(0,0,0,0.1) -20px 20px 40px -4px;
}
.dropdown-boxuser .user-name {
  border-bottom: 1px dashed var(--grayColor);
  margin: 0 0 8px;
}
.dropdown-boxuser .user-name p {
  color: var(--tertiaryColor);
  padding: 4px 10px;
  margin: 0 0 8px;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown-boxuser .user-menus {
  border: none;
}
.dropdown-boxuser .user-menus .menu-item {
  height: auto;
  line-height: 1.6;
  font-weight: 400;
  padding: 4px 12px;
  font-size: 14px;
  color: var(--tertiaryColor);
  transition: 0.5s all;
}
.dropdown-boxuser .user-menus .menu-item:hover {
  transition: 0.5s all;
  background-color: var(--primaryColorAlpha);
}
.dropdown-boxuser .user-menus .menu-item.logout-menu {
  color: var(--errorColor);
  font-weight: 600;
}


/* SIDEBAR ~ CSS */
.sidebar-part {
  background-color: var(--whiteColor);
  height: calc(100vh - 66px);
  overflow: auto;
  overflow-x: hidden;
  box-shadow: 0px 0px 12px -5px var(--grayColor);
}
.sidebar-part .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sidebar-part .sidebar-toggle-part .sidebar-toggle-btn {
  cursor: pointer;
  background-color: transparent;
  padding: 6px 0 0;
  border-top: 1px dashed var(--grayColor);
  border-radius: 0;
}
.sidebar-part .sidebar-toggle-part .sidebar-toggle-btn svg {
  width: 20px;
  transform: rotate(0deg);
  transition: 0.3s all;
  color: var(--secondaryColor);
}
.sidebar-part .sidebar-toggle-part.sidebar-small .sidebar-toggle-btn svg {
  transform: rotate(180deg);
  transition: 0.3s all;
}

/* Sidebar Menu */
.sidebar-part .sidebar-menu {
  margin: 30px 0 0;
  border: none;
}
.sidebar-part .sidebar-menu .ant-menu-item {
  padding: 10px 28px;
  margin: 0 0 6px;
  height: auto;
}
.sidebar-part .sidebar-menu .ant-menu-item a {
  display: flex;
  height: auto;
  align-items: center;
  line-height: 1.6;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  color: var(--secondaryColor);
}
.sidebar-part .sidebar-menu .ant-menu-item a i {
  color: var(--secondaryColor);
  line-height: 0;
}
.sidebar-part .sidebar-menu .ant-menu-item a i svg {
  width: 18px;
  height: 18px;
}
.sidebar-part .sidebar-menu .ant-menu-item:hover {
  background-color: var(--offWhiteColor);
}
.sidebar-part .sidebar-menu .ant-menu-item.ant-menu-item-selected {
  background-color: var(--primaryColorAlpha);
}
.sidebar-part .sidebar-menu .ant-menu-item.ant-menu-item-selected a i {
  color: var(--primaryColor);
}
.sidebar-part .sidebar-menu .ant-menu-item.ant-menu-item-selected a {
  color: var(--primaryColor);
}
/* --Submenu */
.sidebar-part .sidebar-menu .ant-menu-submenu {
  padding: 10px 5px 10px 28px;
  margin: 0 0 6px;
  height: auto;
}
.sidebar-part .sidebar-menu .ant-menu-submenu:hover {
  background-color: var(--offWhiteColor);
}
.sidebar-part .sidebar-menu .ant-menu-submenu .ant-menu-submenu-title {
  height: auto;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}
.sidebar-part .sidebar-menu .ant-menu-submenu .ant-menu-submenu-title span {
  display: flex;
  align-items: center;
  color: var(--secondaryColor);
}
.sidebar-part .sidebar-menu .ant-menu-submenu.ant-menu-submenu-selected {
  background-color: var(--primaryColorAlpha);
}
.sidebar-part .sidebar-menu .ant-menu-submenu.ant-menu-submenu-selected:hover span {
  color: var(--primaryColor);
}
.sidebar-part .sidebar-menu .ant-menu-submenu.ant-menu-submenu-selected span {
  color: var(--primaryColor);
}
.sidebar-part .sidebar-menu .ant-menu-submenu .ant-menu-submenu-title span svg {
  width: 18px;
  height: 18px;
}
.sidebar-part .sidebar-menu .ant-menu-submenu:hover .ant-menu-submenu-title span + i::after,
.sidebar-part .sidebar-menu .ant-menu-submenu:hover .ant-menu-submenu-title span + i::before {
  background: linear-gradient(to right, var(--primaryColor), var(--primaryColor));
}
/* Global --Submenu CSS */
.ant-menu-submenu .ant-menu-sub .ant-menu-item {
  margin: 0;
}
.ant-menu-submenu .ant-menu-sub .ant-menu-item:hover a {
  color: var(--primaryColor);
}
.ant-menu-submenu .ant-menu-sub .ant-menu-item.ant-menu-item-selected {
  background-color: var(--primaryColorAlpha);
}
.ant-menu-submenu .ant-menu-sub .ant-menu-item.ant-menu-item-selected a {
  color: var(--primaryColor);
}
/* --Collapsed */
.sidebar-part .sidebar-menu.ant-menu-inline-collapsed .ant-menu-item {
  padding: 8px 20px !important;
}
.sidebar-part .sidebar-menu.ant-menu-inline-collapsed .ant-menu-item a {
  justify-content: center;
}
.sidebar-part .sidebar-menu.ant-menu-inline-collapsed  .ant-menu-submenu {
  padding: 8px 5px 4px 28px;
  margin: 0 0 6px;
  height: auto;
}
.sidebar-part .sidebar-menu.ant-menu-inline-collapsed  .ant-menu-submenu .ant-menu-submenu-title span i {
  line-height: 1;
}
.sidebar-part .sidebar-menu.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title {
  padding: 0 !important;
}
