.rigImage-part {
    text-align: center;    
}
.rigImage-row {
    display: flex;
    flex-wrap: wrap;
    margin: 8px -12px 32px;
}
.rigImage-row .rigImage-box {
    width: calc(20% - 24px);
    cursor: pointer;
    background-color: var(--whiteColor);
    box-shadow: 0px 0px 2px 1px rgba(145,158,171,0.16);
    margin: 24px 12px 0;
    border-radius: 6px;
    transition: 0.4s all;
}
.rigImage-row .rigImage-box .inner-area {
    padding: 16px;
}
.rigImage-row .rigImage-box img.rig-photo {
    height: 200px;
    width: 100%;
    object-fit: cover;
    border-radius: 6px;
    margin: 0 0 12px;
}
.rigImage-row .rigImage-box h4 {
    font-size: 16px;
    font-weight: 600;
    color: var(--tertiaryColor);
    margin-bottom: 0 !important;
    transition: 0.4s all;
}
.rigImage-row .rigImage-box p {
    margin: 0px;
    color: var(--secondaryColor);
    font-size: 14px;
}
.rigImage-row .rigImage-box:hover h4 {
    color: var(--primaryColor);
    transition: 0.4s all;
}
.rigImage-row .rigImage-box:hover {
    box-shadow: rgba(145,158,171,0.16) 0px 16px 32px -4px;
    transition: 0.4s all;
}


@media (max-width: 1440px) {
    .rigImage-row .rigImage-box {
        width: calc(25% - 24px);
    }
}
@media (max-width: 1280px) {
    .rigImage-row .rigImage-box img.rig-photo {
        height: 170px;
    }
}
@media (max-width: 1024px) {
    .rigImage-row .rigImage-box {
        width: calc(33.33% - 24px);
    }
}
@media (max-width: 991px) {
    .rigImage-row .rigImage-box {
        width: calc(50% - 24px);
    }
}
