/* Search Header */
.search-rig-header {
  margin: 16px 0 24px;
}
.search-rig-header .search-btn-part {
  display: flex;
  flex-wrap: nowrap;
}
.search-rig-header .search-btn-part .export-btn {
  margin: 0 8px;
}
.search-rig-header .search-btn-part .addusers-btn {
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.search-rig-header .search-btn-part .addusers-btn svg {
  width: 18px;
  height: 18px;
  margin: 0 4px 0 0;
}


.ant-dropdown-trigger.user_toggle.ant-dropdown-link.ant-dropdown-open {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.site-layout-background {
  background: #f2f2f2 !important;
}
.containText .ant-calendar-picker-clear, .containText .ant-calendar-picker-icon{
  right: 15px;
}
ul.ant-menu.menuSection.ant-menu-light.ant-menu-root.ant-menu-vertical {
  border-bottom: solid 1px gray;
}
.header_user_details .header_user_name {
	margin-left: 12px;
	margin-right: 8px;
}
.sidebar_section .ant-menu .ant-menu-item .ant-menu-submenu .ant-menu-submenu-title{
  padding: 0 27px !important;
}

.totalRigDown {
  color: red !important;
  font-weight: bold;
}

.totalRigUp {
  font-weight: bold;
}