/* Mobile Preview */
.iphone-content-part {
	position: relative;
}
.iphone-content-part .mobile-preview-content {
    position: absolute;
    z-index: 1;
    background-color: var(--whiteColor);
    background-color: #efeff4;
    width: 244px;
    height: 429px;
    left: 52px;
    top: 67px;
    border-radius: 4px;
    word-break: break-word;
}
.iphone-content-part .mobile-preview-content .mobile-scrolling-area {
    height: calc(100% - 76px);
    overflow: hidden;
    overflow-y: auto;
}

/* Mobile Submit Button */
.mobile-submit-btn {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 12px 16px;
    height: 76px;
	background-color: var(--whiteColor);
}
.mobile-submit-btn .m-btn {
    height: 44px;
    border-radius: 40px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.5px;
    background: var(--primaryColorGB);
    color: var(--whiteColor);
    box-shadow: 0px 10px 10px -7px var(--grayColor);
    border: none;
}

/* Mobile Preview ~ Components */
.mobile-field {
	margin: 0 0 10px 0;
	font-size: 12px;
    background-color: var(--whiteColor);
}
.mobile-field p {
	margin: 0;
}
.mobile-field .m-box-header {
    padding: 12px 16px;
	color: var(--tertiaryColor);
	border-bottom: 1px solid var(--primaryColorAlpha);
}
.mobile-field .m-box-header p.title {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	font-weight: 700;
	font-size: 12px;
	margin: 0px;
	letter-spacing: 0.2px;
}
.mobile-field .m-box-header .require-symbol {
    position: absolute;
    left: -8px;
    top: 2px;
	color: var(--errorColor) !important;
}
.mobile-field .m-box-header .subtitle {
	display: block;
	width: 100%;
	font-size: 10px;
	font-weight: 500;
	letter-spacing: 0.5px;
	color: var(--secondaryColor);
}
.mobile-field .m-box-header.checkbox .custom-checkbox {
    margin: 0;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
}
.mobile-field .m-box-header.checkbox .custom-checkbox span {
    padding-left: 0;
}
.mobile-field .m-box-content {
    padding: 12px 16px;
}
.mobile-field .m-box-content .m-input {
	border: none;
	border-bottom: 1px solid var(--grayColor);
	font-size: 12px;
    border-radius: 0px;
    padding: 0;
}
.mobile-field .m-box-content .m-input:focus {
	outline: none;
	box-shadow: none;
}
.mobile-field .m-box-content .text-area {
	border: none;
	font-size: 12px;
    border-radius: 0px;
    padding: 0;
}
.mobile-field .m-box-content .text-area:focus {
	outline: none;
	box-shadow: none;
}

/* Custom Checkbox Mobile */
.custom-checkbox.round {
	width: 100%;
    margin: 8px 0 0;
}
.custom-checkbox.round label {
	display: inline-block;
}
.custom-checkbox.round span {
	font-size: 12px;
    border-radius: 50%;
}
.custom-checkbox.round .ant-checkbox-checked .ant-checkbox-inner {
	background-color: var(--primaryColor);
	border-color: var(--primaryColor);
}
.custom-checkbox.round .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.custom-checkbox.round .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.custom-checkbox.round .ant-checkbox:hover .ant-checkbox-inner,
.custom-checkbox.round .ant-checkbox-input:focus + .ant-checkbox-inner {
	border-color: var(--primaryColor);
}
.custom-checkbox.round .ant-checkbox-checked::after {
    border-radius: 50%;
}
.custom-checkbox.round:first-child {
	margin: 0px;
}

/* Mobile Rating */
.mobile-field .m-star-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-size: 24px;
}
.mobile-field .m-star-group i {
    margin: 0 4px;
    color: var(--primaryColor);
    cursor: pointer;
}
.mobile-field .ant-rate-star-full {
    color: var(--primaryColor);
}
.mobile-field .m-star-rating-text {
    margin: 8px 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.mobile-field .m-star-rating-text span {
    width: calc(50% - 10px);
    text-align: center;
    background: var(--primaryColorAlpha);
    display: inline-block;
    font-size: 10px;
    font-weight: 600;
    padding: 4px 2px;
    border-radius: 18px;
}

/* Mobile Switch */
.mobile-field .yes-no-switch {
    width: 100%;
    font-size: 12px;
    font-weight: 500;
}
.mobile-field .yes-no-switch label {
    width: 50%;
    text-align: center;
    height: 30px;
    line-height: 30px;
}
.mobile-field .yes-no-switch label.ant-radio-button-wrapper-checked {
    background: var(--primaryColor);
    border-color: var(--primaryColor);
}

/* Mobile Image upload */
.mobile-field .add-img-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid var(--grayColor);
    color: var(--secondaryColor);
    font-weight: 500;
    background: transparent;
    height: 40px;
    border-radius: 4px;
}
.mobile-field .add-img-btn i {
    margin: 0 8px 0 0;
    font-size: 16px;
}

/* Mobile Range Slider */
.mobile-field .m-rangslider {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.mobile-field .m-rangslider .custom-range-slider {
    width: calc(100% - 78px);
    margin: 0px;
}
.mobile-field .m-rangslider .custom-range-slider .ant-slider-track {
    background-color: var(--primaryColor);
}
.mobile-field .m-rangslider .custom-range-slider .ant-slider-rail {
    background-color: var(--primaryColorAlpha);
}
.mobile-field .m-rangslider .custom-range-slider .ant-slider-handle {
    border: 2px solid var(--primaryColor);
}
.mobile-field .m-rangslider .slider-count {
    width: 60px;
}

/* Error */
.mobile-field.error .m-box-header {
	color: var(--errorColor);
}

.mobile-scrolling-area .spinner {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}